import React from "react";
import './Projects.css'
import myDuka from '../../projects/myDuka1.png'
import kro from '../../projects/kropay.png';
import pettykash from '../../projects/pettykash.png'
import kodi from '../../projects/kodi.png'
import theerema from '../../projects/theerema.png'
import { SiRuby, SiTypescript, SiPython, SiReact, SiNodedotjs, SiExpress, SiFlask, SiDjango, SiGmail, SiJavascript, SiRubyonrails, SiMaterialdesignicons, SiTailwindcss } from 'react-icons/si'



function Projects(){

	// add costshare

    return(
      <>

<section className=" dark:bg-black dark:text-gray-100">
	<div className="container p-6 mx-auto space-y-8">
		<div className=" text-center">
			<h2 className="text-3xl font-bold">Passion Projects</h2>
			<p className="font-serif text-sm dark:text-gray-400">A sample of some of my projects</p>
		</div>
		<div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-4">


			<article className="flex flex-col dark:bg-gray-900  hover:scale-110">
				<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum">
					<img alt="" className="object-cover w-full h-52 dark:bg-gray-500" src={kro} />
				</a>
				<div className="flex flex-col flex-1 p-6">
					<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum"></a>
					<a rel="noopener noreferrer" href="#" className="text-xs tracki uppercase hover:underline dark:text-red-400">Kropay</a>
					<h3 className="flex-1 py-2 text-lg font-semibold leadi">A web app that acts as a middleman to social e-commerce payments</h3>
					<div className="flex flex-wrap justify-between pt-3 space-x-2 text-xs dark:text-gray-400">
						<span>Technologies Used:</span>
						<SiRuby/> <SiJavascript/> <SiReact/> <SiRubyonrails/> <SiMaterialdesignicons/> <SiTailwindcss/>
					</div>
				</div>
			</article>

			<article className="flex flex-col dark:bg-gray-900 hover:scale-110">
				<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum">
					<img alt="" className="object-cover w-full h-52 dark:bg-gray-500" src={pettykash} />
				</a>
				<div className="flex flex-col flex-1 p-6">
					<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum"></a>
					<a rel="noopener noreferrer" href="#" className="text-xs tracki uppercase hover:underline dark:text-red-400">PettyKash</a>
					<h3 className="flex-1 py-2 text-lg font-semibold leadi">A web app for company petty cash management</h3>
					<div className="flex flex-wrap justify-between pt-3 space-x-2 text-xs dark:text-gray-400">
						<span>Technologies used: </span>
						<SiRuby/> <SiJavascript/> <SiReact/> <SiRubyonrails/> <SiMaterialdesignicons/> <SiTailwindcss/>
					</div>
				</div>
			</article>

			<article className="flex flex-col dark:bg-gray-900 hover:scale-110">
				<a rel="noopener noreferrer" href="https://www.kodihomes.co.ke/" aria-label="Te nulla oportere reprimique his dolorum">
					<img alt="" className="object-cover w-full h-52 dark:bg-gray-500" src={kodi} />
				</a>
				<div className="flex flex-col flex-1 p-6">
					<a rel="noopener noreferrer" href="https://www.kodihomes.co.ke/" className="text-xs tracki uppercase hover:underline dark:text-red-400">Kodi</a>
					<h3 className="flex-1 py-2 text-lg font-semibold leadi">A landlord, agent, caretaker and tenant management system</h3>
					<div className="flex flex-wrap justify-between pt-3 space-x-2 text-xs dark:text-gray-400">
						<span>Technologies used:</span>
						<SiRuby/> <SiJavascript/> <SiReact/> <SiMaterialdesignicons/> <SiTailwindcss/>  <SiNodedotjs/>
					</div>
				</div>
			</article>

			<article className="flex flex-col dark:bg-gray-900 hover:scale-110">
				<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum">
					<img alt="" className="object-cover w-full h-52 dark:bg-gray-500" src={theerema} />
				</a>
				<div className="flex flex-col flex-1 p-6">
					<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum"></a>
					<a rel="noopener noreferrer" href="#" className="text-xs tracki uppercase hover:underline dark:text-red-400">Theerema</a>
					<h3 className="flex-1 py-2 text-lg font-semibold leadi">A logistic, pick and drop company system </h3>
					<div className="flex flex-wrap justify-between pt-3 space-x-2 text-xs dark:text-gray-400">
						<span>Technologies used:</span>
						<SiRuby/> <SiJavascript/> <SiReact/> <SiRubyonrails/> <SiMaterialdesignicons/> <SiTailwindcss/>
					</div>
				</div>
			</article>

      <article className="flex flex-col dark:bg-gray-900 hover:scale-110">
				<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum">
					<img alt="" className="object-cover w-full h-52 dark:bg-gray-500" src={myDuka} />
				</a>
				<div className="flex flex-col flex-1 p-6">
					<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum"></a>
					<a rel="noopener noreferrer" href="#" className="text-xs tracki uppercase hover:underline dark:text-red-400">MyDuka</a>
					<h3 className="flex-1 py-2 text-lg font-semibold leadi">A merchant inventory management system. </h3>
					<div className="flex flex-wrap justify-between pt-3 space-x-2 text-xs dark:text-gray-400">
						<span>Technologies used:</span>
						<SiRuby/> <SiJavascript/> <SiReact/> <SiMaterialdesignicons/> <SiTailwindcss/>  <SiNodedotjs/>
						
					</div>
				</div>
			</article>
		</div>
	</div>
</section>

        {/* <div className="projo">
<div className="wrapper">
  <header className="header">
    <h1>Projects from The Heart</h1>
  </header>
  <figure className="featured-image-1">
    <img
      src="https://images.pexels.com/photos/3934623/pexels-photo-3934623.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
      alt="My Duka"
    />
  </figure>
  <article className="article article-1">
    
    <p>
      
    </p>
  </article>
  <figure className="featured-image-2">
    <img
      src={myDuka}
      alt="RealState"
    />
  </figure>
  <div className="number-2">1</div>
  <article className="article article-2">
    <h2>My Duka </h2>
    <p className="txt">
    An intuitive innventory app. Visualize your inventory. <br/> Built with <SiRuby/>, <SiJavascript/>, <SiReact/>, <SiRubyonrails/>, <SiMaterialdesignicons/> , <SiTailwindcss/>
    </p>
  </article>
  <figure className="featured-image-3">
    <img
      src="https://img.freepik.com/free-vector/website-construction-background_1361-1388.jpg"
      alt="img"
    />
  </figure>
  <div className="number-3">2</div>
  <article className="article article-3">
    <h2>RealState</h2>
    <p className="txt">
    An apartment management system for landlords. <br/> Built with <SiTypescript/>,  <SiNodedotjs/>, <SiExpress/>, <SiReact/>, <SiJavascript/>, <SiTailwindcss/>
    </p>
  </article>
  <figure className="featured-image-4">
    <img
      src="https://images.pexels.com/photos/211122/pexels-photo-211122.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
      alt="Photo by Tim Marshall on Unsplash"
    />
  </figure>
  <div className="number-4">3</div>
  <article className="article article-4">
    <h2>Krw</h2>
    <p className="txt">
      An escrow system that acts as athird party agent for online sellers and buyers. Money is released upon the set conditions being met. <br/>Technologies used <SiRuby/>, <SiJavascript/>, <SiReact/>, <SiRubyonrails/>, <SiMaterialdesignicons/>, <SiTailwindcss/>
    </p>
  </article>
</div>
</div> */}


      </>





    )
}

export default Projects;