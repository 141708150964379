import React from "react";
import {Link, NavLink} from "react-router-dom"
import "./Navbar.css"

function Navbar(){

    return(
        <>
        <nav>
            <NavLink to="/main/about" activeClassNAame="active"  >ABOUT</NavLink>
            <NavLink to="/main/projects" activeClassNAame="active" >PROJECTS</NavLink>
            <NavLink to="/main/contacts" activeClassNAame="active" >CONTACTS</NavLink>
            <div id="indicator"></div>
        </nav>
        </>
    )
}

export default Navbar;