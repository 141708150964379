import React from "react";
import './Landingpage.css'
import DiscoverMe from "./DiscoverMe";


function Landingpage(){

    return(
        <>

        {/* <nav className="btn-pluss-wrapper">
            <h2 className="tooltip">Me!</h2>
            <div href="#" className="btn-pluss">
            <ul>
                <li><a href="#about">About me</a></li>
                <li><a href="#blog">Skills</a></li>
                <li><a href="#projects">Projects</a></li>
                <li><a href="#contact">Contact</a></li>
                </ul>
            </div>
        </nav> */}


        <DiscoverMe/>










        <div className = "cont">
            <h1>Hi, my name is</h1>
            <h1>Kimathi Njoki.</h1>
        </div>

        </>
    )
}

export default Landingpage;