import './portfolio.css'
import { AiOutlineDownload} from 'react-icons/ai'
import resume from '../../projects/KimathiNjoki.pdf'


function Cv(){
    return(
        <>

    <div id='cv'>

    <a href={resume} className="cv">
        <span>cv</span>
        <div className="liquid"></div>
    </a>

    </div>

        </>
    )
}

export default Cv;