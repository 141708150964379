import React from "react";
import './About.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AiFillHtml5, AiOutlineCode, AiFillGithub, AiFillLinkedin, AiFillTwitterCircle } from 'react-icons/ai'
import { FaCss3Alt, FaCode } from 'react-icons/fa'
import { IoLogoJavascript} from 'react-icons/io'
import { SiRuby, SiTypescript, SiPython, SiReact, SiNodedotjs, SiExpress, SiFlask, SiDjango, SiGmail, SiRubyonrails} from 'react-icons/si'
import Cv from "./portfolio";
import me from '../../projects/me.jpeg'

function About(){

    const target = {
        clicked: 0,
        currentFollowers: 90,
        btn: document.querySelector("a.btn"),
        fw: document.querySelector("span.followers")
      };
      
      const follow = () => {
        target.clicked += 1;
        target.btn.innerHTML = 'Following <i class="fas fa-user-times"></i>';
      
        if (target.clicked % 2 === 0) {
          target.currentFollowers -= 1;
          target.btn.innerHTML = 'Follow <i class="fas fa-user-plus"></i>';
        }
        else {
          target.currentFollowers += 1;
        }
      
        target.fw.textContent = target.currentFollowers;
        target.btn.classList.toggle("following");
      }
      

    
    return(
        
        <div className="aboutMe">



<div className="cards">
<div className="card1">
  <div className="ds-top"> 


 
  </div>
  <div className="avatar-holder">
       <img
      src={me}
      alt="kimathi Njoki"
    />
  </div>
  
  <div className="name">
    <a href="https://codepen.io/AlbertFeynman/" target="_blank">
      Kimathi Njoki
    </a>

  </div>
  <div className="button">
    <a href="#" className="btn" onmousedown="follow();">
      Software Engineer 
    </a>
  </div>

  <br/>


  <div className="ds-info">
    <div className="ds pens">
      <h6 title="Number of years of experience">
      <a href="https://github.com/kimathinjoki" className="link">
         <i><AiFillGithub/> </i>
    </a>
      </h6>
        
    </div> 
    <div className="ds pens">
      <h6 title="Number of projects created by the user">
        <a href="mailto:dfkimathinjoki@gmail.com" className="link">
            <i><SiGmail/> </i>
        </a>
      </h6>
      
    </div>
    <div className="ds pens">
      <h6 title="Number of posts">
        <a href="http://www.linkedin.com/in/kimathi-njoki-03b6031b0/"  className="link">
    <i> <AiFillLinkedin/> </i>
    </a>
      </h6>
    
    </div>

    <div className="ds pens">
      <h6 title="Number of posts">
     < a href="https://twitter.com/kimathi_njoki" className="link">
    <i> <AiFillTwitterCircle/> </i>
    </a>
      </h6>
    
    </div>


  </div>


 

  <br/>


  <div className="ds-skill">
    <h4>
      About <i className="fa fa-code" aria-hidden="true" />
    </h4>
    <div className="skill">
      <div className="bar bar-html">






      

      
      </div>

      <br/>
    
      <p id='about'> I'm a Software Engineer, a former Medical Technologist and a creative. I love building APIs, desktop and server software. Am passionate about AI and it's integration into preexisting system. 
      I'm always curious to learn more when it comes to new technologies and perpetually improving my
      skills one software development at a time. </p>


    </div>

  </div>





 
</div>




<div className="card" id="skills">

  <div className="ds-skill">
    <h6 id="skill">
      Skills <i> <FaCode/> </i>
    </h6>
    <div className="skill html">
      <h6>
        <i className="fab"> <AiFillHtml5/> </i> HTML5
      </h6>
      <div className="bar bar-html">
        <p>95%</p>
      </div>
    </div>

    <div className="skill css">
      <h6>
      <i className="fab"> <FaCss3Alt/> </i> CSS3{" "}
      </h6>
      <div className="bar bar-css">
        <p>90%</p>
      </div>
    </div>

    <div className="skill ruby">
      <h6>
        <i className="fab"> <SiRuby/> </i> Ruby{" "}
      </h6>
      <div className="bar bar-rb">
        <p>81%</p>
      </div>
    </div>

    <div className="skill ruby">
      <h6>
        <i className="fab"> <SiRubyonrails/> </i> Ruby on Rails{" "}
      </h6>
      <div className="bar bar-rb">
        <p>81%</p>
      </div>
    </div>

    <div className="skill javascript">
      <h6>
      <i className="fab"> <IoLogoJavascript/> </i> JavaScript{" "}
      </h6>
      <div className="bar bar-js">
        <p>75%</p>
    </div>
    
    <div className="skill html">
      <h6>
      <i className="fab"> <SiTypescript/> </i> Typescript{" "}
      </h6>
      <div className="bar bar-ts">
        <p>73%</p>
      </div>
    </div>

    <div className="skill html">
      <h6>
      <i className="fab"> <SiPython/> </i> Python{" "}
      </h6>
      <div className="bar bar-py">
        <p>72%</p>
      </div>
    </div>

    <div className="skill html">
      <h6>
      <i className="fab"> <SiReact/> </i> React.js{" "}
      </h6>
      <div className="bar bar-jsx">
        <p>71%</p>
      </div>
    </div>

    <div className="skill html">
      <h6>
      <i className="fab"> <SiNodedotjs/> </i> Node.js{" "}
      </h6>
      <div className="bar bar-nd">
        <p>70%</p>
      </div>
    </div>

    <div className="skill html">
      <h6>
      <i className="fab"> <SiExpress/> </i> Express.js{" "}
      </h6>
      <div className="bar bar-ex">
        <p>70%</p>
      </div>
    </div>

    <div className="skill html">
      <h6>
      <i className="fab"> <SiFlask/> </i> Flask{" "}
      </h6>
      <div className="bar bar-fk">
        <p>66%</p>
      </div>
    </div>

    <div className="skill html">
      <h6>
      <i className="fab"> <SiDjango/> </i> Django{" "}
      </h6>
      <div className="bar bar-dj">
        <p>63%</p>
      </div>
    </div>
    </div>
  </div>
</div>

</div>


    <Cv/>






        </div>
    )
}

export default About;