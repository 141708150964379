import './contact.css'
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function Contact() {
  const form = useRef();
  const [showModal, setShowModal] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_alhnifo', 'template_rqnj8gj', form.current, 'auJQ2QnXO8_bmkOdn')
      .then((result) => {
        console.log(result.text);
        console.log("message sent");
        resetForm();
        // setShowModal(true);
        // setTimeout(() => {
        //   setShowModal(false);
        // }, 4000);
      }, (error) => {
        console.log(error.text);
      });
  };

  const resetForm = () => {
    setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 4000);
    form.current.reset();
  };

  return (
    <>
      <nav className="social">
        <ul>
          <li><a href="https://twitter.com/kimathi_njoki" className='link'>Twitter <i className="fa fa-twitter"></i></a></li>
          <li><a href="https://github.com/kimathinjoki" className='link'>Github <i className="fa fa-github"></i></a></li>
          <li><a href="https://www.linkedin.com/in/kimathi-njoki-03b6031b0/" className='link'>Linkedin <i className="fa fa-linkedin"></i></a></li>
        </ul>
      </nav>

      <div className="background">
        <div className="container">
          <div className="screen">
            <div className="screen-body">
              <div className="screen-body-item left">
                <div className="app-title">
                  <span>CONTACT</span>
                  <span>ME</span>
                </div>
                <div className="app-contact">CONTACT: +1 636 849 0434</div>
              </div>
              <div className="screen-body-item">
                <form className="app-form" ref={form} onSubmit={sendEmail}>
                  <div className="app-form-group">
                    <input
                      className="app-form-control"
                      placeholder="NAME"
                      name="user_name"
                    />
                  </div>
                  <div className="app-form-group">
                    <input className="app-form-control" placeholder="EMAIL" name="user_email" />
                  </div>
                  <div className="app-form-group">
                    <input className="app-form-control" placeholder="CONTACT NO" name="contact_number" />
                  </div>
                  <div className="app-form-group message">
                    <input className="app-form-control" placeholder="MESSAGE" name="message" />
                  </div>
                  <div className="app-form-group buttons">
                    <button type='submit' className="app-form-button">SEND</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Message sent</p>
          </div>
        </div>
      )}
    </>
  )
}

export default Contact;