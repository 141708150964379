import './App.css';
import {Navigate, Route, Routes} from "react-router-dom"
import Landingpage from './components/landingpage/Landingpage';
import Main from './components/main/Main';
import About from './components/about/About';
import Projects from './components/about/Projects';
import Contact from './components/about/Contact'
import { useState } from 'react';

function App() {

  const [home, setHome] = useState(false)

function goHome(){
  setHome(true)
}

if(home){
  return <Navigate to="/main/about" />
}

  return (
    <>

    <div>
      <p id="logo">KN</p>
    </div>

    <Routes>


      <Route path="/" element={<Landingpage/>} />
      <Route path='/main' element={<Main/>}>
        <Route path='/main/about' element={<About/>} />
        <Route path='/main/contacts' element={<Contact/>} />
        <Route path='/main/projects' element={<Projects/>} />
        <Route path='/main/about' element={<Main/>} />

      </Route>

    </Routes>

    
{/* 
    <footer>
    Copyright &copy; Kimathi Njoki 2023
   </footer> */}

<footer className="px-4 py-2 dark:bg-black dark:text-gray-400">
	<div className="container flex  justify-center mx-auto space-y-5  ">
  <p>Copyright &copy; Kimathi Njoki 2024
		</p>
	</div>
</footer>


    </>
   
  );
}

export default App;
